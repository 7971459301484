<template>
  <div class="wrapper">
    <common-header></common-header>
    <div class="content-box" :class="{'content-collapse':collapse}">
      <common-tags></common-tags>
      <div class="content">
        <transition name="move" mode="out-in">
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </transition>
        <el-backtop target=".content"></el-backtop>
      </div>
    </div>
  </div>
</template>

<script>
import commonHeader from "./common/commonHeader";
import commonTags from "./common/commonTags";
import bus from "../service/bus";

export default {
  components: {
    commonHeader,
    commonTags,
  },
  data() {
    return {
      collapse: true,
    };
  },
  created() {
  },
};
</script>

<style scoped>
</style>
